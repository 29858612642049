import { DataGrid } from "@mui/x-data-grid"

const Table = ({ columns, rows }) => {
    return (
        <DataGrid
            columns={columns}
            rows={rows}
            rowSelection={false}
            sx={{
                '& .MuiDataGrid-columnHeader': {
                    backgroundColor: '#15322D',
                    color: 'white',
                },
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                },
                backgroundColor: '#C9D4C4',
                color: '#15322D',
                fontSize: 20
            }}
        />
    )
}

export default Table