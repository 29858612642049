
import React, { useState } from 'react';
import { Container, Grid, Button, TextField, Typography, InputAdornment,IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


const ProfileEdit = ({ userData, handleBackButton, handleSaveButton,handleInputChange }) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <>
            <Container maxWidth="lg" sx={{ mt: 14, textAlign: 'center' }}>
                <Grid container spacing={2} sx={{ border: '1px solid #ccc', borderRadius: 2 }}>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 1, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                ชื่อผู้ใช้
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            sx={{
                                p: 1,
                                borderBottom: '1px solid #ccc',
                                transition: 'background-color 0.3s',
                            }}
                        >
                            <TextField
                                variant="standard"
                                name='username'
                                fullWidth
                                value={userData.username}
                                onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
                            />
                        </Grid>
                    </React.Fragment>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 1, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                รหัสผ่าน
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            sx={{
                                p: 1,
                                borderBottom: '1px solid #ccc',
                                transition: 'background-color 0.3s',
                            }}
                        >
                            <TextField
                                variant="standard"
                                fullWidth
                                name='password'
                                type={!showPassword ? 'password' : 'text'}
                                onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                
                            />
                        </Grid>
                    </React.Fragment>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 1, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                เบอร์ติดต่อ
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            sx={{
                                p: 1,
                                borderBottom: '1px solid #ccc',
                                transition: 'background-color 0.3s',
                            }}
                        >
                            <TextField
                                variant="standard"
                                fullWidth
                                name='tel_no'
                                inputProps={{ maxLength: 10 }}
                                value={userData.tel_no}
                                onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
                            />
                        </Grid>
                    </React.Fragment>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 1, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                ชื่อ
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            sx={{
                                p: 1,
                                borderBottom: '1px solid #ccc',
                                transition: 'background-color 0.3s',
                            }}
                        >
                            <TextField
                                variant="standard"
                                fullWidth
                                name='firstname'
                                value={userData.firstname}
                                onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
                            />
                        </Grid>
                    </React.Fragment>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 1, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                นามสกุล
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            sx={{
                                p: 1,
                                borderBottom: '1px solid #ccc',
                                transition: 'background-color 0.3s',
                            }}
                        >
                            <TextField
                                variant="standard"
                                fullWidth
                                name='lastname'
                                value={userData.lastname}
                                onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
                            />
                        </Grid>
                    </React.Fragment>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 1, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                ที่อยู่
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            sx={{
                                p: 1,
                                borderBottom: '1px solid #ccc',
                                transition: 'background-color 0.3s',
                            }}
                        >
                            <TextField
                                variant="standard"
                                fullWidth
                                name='address'
                                value={userData.address}
                                onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
                            />
                        </Grid>
                    </React.Fragment>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 1, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                เลขประจำตัวผู้เสียภาษี
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            sx={{
                                p: 1,
                                borderBottom: '1px solid #ccc',
                                transition: 'background-color 0.3s',
                            }}
                        >
                            <TextField
                                variant="standard"
                                fullWidth
                                name='tax_id'
                                value={userData.tax_id}
                                onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
                            />
                        </Grid>
                    </React.Fragment>

                </Grid>
            </Container>
            <Container maxWidth="lg" sx={{ mt: 2, textAlign: 'center', p: 5 }}>
                <Button
                    onClick={handleBackButton}
                    variant="contained"
                    sx={{
                        backgroundColor: '#C9D4C4',
                        color: '#15322D',
                        fontSize: 20,
                        '&:hover': {
                            backgroundColor: '#15322D',
                            color: 'white'
                        },
                        mr: 4,
                        px: 15,
                    }}
                >
                    กลับ
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSaveButton}
                    sx={{
                        backgroundColor: '#B5D86C',
                        color: '#15322D',
                        fontSize: 20,
                        px: 15,
                        '&:hover': {
                            backgroundColor: '#15322D',
                            color: 'white'
                        },
                    }}
                >
                    บันทึก
                </Button>
            </Container>
        </>
    );
}

export default ProfileEdit
