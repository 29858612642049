import Table from "../table"
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import { IconButton, Tooltip, Typography } from "@mui/material"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const OrdersTable = ({ rows, handleAccept, handleOpenDialog }) => {
    const columns = [
        // { field: 'id', headerName: 'ID', width: 0, align: 'center' },
        { field: 'orders_no', headerName: 'เลขคำสั้งซื้อ', flex: 0.2, align: 'center' },
        { field: 'orderer', headerName: 'ผู้สั่ง', flex: 0.2, align: 'center' },
        { field: 'order_date', headerName: 'วันที่สั่ง', flex: 0.2, align: 'center' },
        { field: 'status', headerName: 'สถานะ', flex: 0.2, align: 'center' },
        { field: 'product', headerName: 'สินค้า', flex: 0.5, align: 'center' },
        { field: 'brand', headerName: 'ยี่ห้อ', flex: 0.2, align: 'center' },
        { field: 'quantity', headerName: 'จำนวน', flex: 0.2, align: 'center' },
        { field: 'total_weight', headerName: 'น้ำหนักสุทธิ', flex: 0.2, align: 'center' },
        { field: 'delivery_date', headerName: 'วันที่ส่งมอบ', flex: 0.2, align: 'center' },
        {
            field: "accept",
            headerName: "",
            flex: 0.1,
            align: 'center',
            renderCell: (params) => {
                return params.row.status === 'กำลังจัดส่ง' ? (
                    <Tooltip title={<Typography>ยอมรับคำสั่งซื้อ</Typography>}>
                        <IconButton color="primary" onClick={() => handleAccept(params.row.id)}>
                            <HowToRegOutlinedIcon sx={{ color: '#15322D', fontSize: 40 }} />
                        </IconButton>
                    </Tooltip>
                ) : null;
            },
        },
        {
            field: "info",
            headerName: "",
            flex: 0.1,
            align: 'center',
            renderCell: (params) => (
                <Tooltip title={<Typography>หมายเหตุ</Typography>} >
                    <IconButton color="primary" onClick={() => handleOpenDialog(params.row.remark)}>
                        <InfoOutlinedIcon sx={{ color: '#15322D', fontSize: 40 }} />
                    </IconButton>
                </Tooltip>
            ),
        }
    ]
    return (
        <div style={{ height: 600, width: "100%" }}>
            <Table
                columns={columns}
                rows={rows}
            />
        </div>
    )
}
export default OrdersTable