import Table from "../table"
import SearchIcon from '@mui/icons-material/Search';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Tooltip, IconButton, Typography } from '@mui/material'

const WarehouseTable = ({ rows, onProcess }) => {
    const columns = [
        { field: 'batch_no', headerName: 'หมายเลขชุด', flex: 0.5, align: 'center' },
        { field: 'product', headerName: 'ชื่อสินค้า', flex: 0.5, align: 'center' },
        { field: 'create_date', headerName: 'วันที่ผลิด', flex: 0.5, align: 'center' },
        { field: 'quantity', headerName: 'จำนวน', flex: 0.5, align: 'center' },
        {
            field: 'change',
            headerName: '',
            flex: 0.05,
            align: 'center',
            renderCell: (params) => (
                <Tooltip title={<Typography>แปรรูป</Typography>}>
                    <IconButton color="primary" onClick={() => onProcess(params.row)}>
                        <AutorenewIcon sx={{ color: '#15322D', fontSize: 35 }} />
                    </IconButton>
                </Tooltip>
            )
        },
        {
            field: 'accept',
            headerName: '',
            flex: 0.05,
            align: 'center',
            renderCell: (params) => (
                <Tooltip title={<Typography>ข้อมูล</Typography>}>
                    <IconButton color="primary" onClick={() => console.log('detail')}>
                        <SearchIcon sx={{ color: '#15322D', fontSize: 35 }} />
                    </IconButton>
                </Tooltip>
            )
        },

    ]
    return (
        <div style={{ height: 400, width: "100%" }}>
            <Table
                rows={rows}
                columns={columns}
            />
        </div>
    )
}
export default WarehouseTable