import React, { useEffect, useState } from 'react';

import { Container, Typography, Paper, Grid, Button } from '@mui/material';
import Layout from '../../components/layout';
import ProfileView from '../../components/profile/view';
import ProfileEdit from '../../components/profile/edit';
import FarmerViewProfile from '../../components/profile/view.farmer'
import { getFarmerProfile, getProfile, updateProfile } from '../../services/profile';
import { getCookie } from '../../utils/cookie';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';



const ProfilePage = () => {
    const [mode, setMode] = useState('view')
    const [farmerData, setFarmerData] = useState(null)
    const [editData, setEditData] = useState(
        {
            id: '',
            username: '',
            password: '',
            tel_no: '',
            firstname: '',
            lastname: '',
            address: '',
            tax_id: ''
        }
    )
    const [userData, setUserData] = useState(
        {
            id: '',
            username: '',
            password: '*******',
            tel_no: '',
            firstname: '',
            lastname: '',
            address: '',
            tax_id: ''
        }
    )
    const user_id = localStorage.getItem('user_id')
    const token = localStorage.getItem('accessToken')
    const role_id = localStorage.getItem('role_id')
    let navigate = useNavigate()

    useEffect(() => {
        console.log(role_id)
        if (role_id !== "4") {
            fetchProfile()
        } else {
            fetchFarmerProfile()
        }
    }, [])

    const fetchFarmerProfile = () => {
        getFarmerProfile(token)
        .then(res => {
            let data = res.data.data
            setFarmerData(data)
        }).catch(error => {
            console.log(error.response)
            if (error.response.status === 403) {
                navigate('/login')
            }
        })
    }

    const fetchProfile = () => {
        getProfile(user_id, token)
            .then((res) => {
                let data = res.data.data
                setUserData(data)
                setEditData(data)
            }).catch(error => {
                console.log(error.response)
                if (error.response.status === 403) {
                    navigate('/login')
                }
            })
    }

    const handleInputChange = (change) => {
        setEditData((prevUser) => ({
            ...prevUser,
            [change.name]: change.value,
        }));
    };

    const handleEditButton = () => {
        setMode('edit')
    }
    const handleBackButton = async () => {
        await fetchProfile()
        setMode('view')
    }
    const handleSaveButton = () => {
        const filteredData = Object.fromEntries(
            Object.entries(editData).filter(([key, value]) => value !== '' && value !== null && value !== undefined)
        );

        updateProfile(filteredData, token)
            .then(res => {
                Swal.fire({
                    icon: "success",
                    title: "บันทึกสำเร็จ"
                }).then(() => {
                    fetchProfile()
                    setMode('view')
                }
                )
            }).catch(
                err => console.log(err)
            )

    }
    if(role_id !== "4") {
    return (
        <Layout title='ข้อมูลส่วนตัว'>
            {mode === 'view' ?
                <ProfileView handleEditButton={handleEditButton} userData={userData} /> :
                <ProfileEdit userData={editData} handleBackButton={handleBackButton} handleSaveButton={handleSaveButton} handleInputChange={handleInputChange} />
            }
        </Layout>
    );
    }
    else {
        return (
            <Layout title='ข้อมูลส่วนตัว'>
                <FarmerViewProfile farmerData={farmerData} handleEditButton={handleEditButton} />
            </Layout>
        )
    }
};

export default ProfilePage;
