import axios from 'axios'

const config = require('../config.js')

const API_URL = config.API_URL

export const getVendorById = (id,token) =>{
    return axios.post(`${API_URL}/vendor/getById`,{vendor_id : id},{
        headers : {
            Authorization: `Bearer ${token}`
        }
    })
}

export const getVendorList = (payload,token) => {
    return axios.post(`${API_URL}/vendor/get_friend`,payload,{
        headers : {
            Authorization: `Bearer ${token}`
        }
    })
}

export const addFriendVendor = (payload,token) => {
    return axios.post(`${API_URL}/vendor/add_friend`,payload, {
        headers : {
            Authorization: `Bearer ${token}`
        }
    })
}

export const searchVendor = (payload,token) =>{
    return axios.post(`${API_URL}/vendor/search`,payload, {
        headers : {
            Authorization: `Bearer ${token}`
        }
    })
}

export const deleteVendor = (payload,token) => {
    return axios.post(`${API_URL}/vendor/delete`,payload, {
        headers : {
            Authorization: `Bearer ${token}`
        }
    })
}

export const getVendorsByUserId = (payload,token) =>{
    return axios.post(`${API_URL}/vendor/getByUserId`, payload,{
        headers : {
            Authorization: `Bearer ${token}`
        }
    })
}
