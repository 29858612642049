import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Radio, RadioGroup, FormControlLabel, Button, Typography } from '@mui/material';

const AcceptRadio = ({ open, setOpen, handleAccept, setOption, option }) => {


    const handleClose = () => {
        setOpen(false)
    }


    const handleCancel = () => {
        handleClose();
    };

    const handleChange = (event) => {
        setOption(event.target.value);
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: '90vh', color: "#15322D" } }}>
                {/* ↑↑ Adjust the width and maxWidth as needed ↑↑ */}
                <DialogTitle sx={{ fontSize: 30 }}>การตอบรับ</DialogTitle>
                <DialogContent>
                    <RadioGroup value={option} onChange={handleChange} >
                        <FormControlLabel value="option1"
                            control={<Radio sx={{
                                '& .MuiSvgIcon-root': {
                                  fontSize: 28,
                                },
                              }} />}
                            label={<Typography sx={{fontSize:22}}>ยอมรับคำสั่งซื้อและส่งสินค้า</Typography>}
                        />
                        <FormControlLabel 
                        value="option2" 
                        control={<Radio sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 28,
                            },
                          }}
                        />} 
                        label={<Typography sx={{fontSize:22}}>ยกเลิกคำสั่งซื้อ</Typography>} />
                    </RadioGroup>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleClose} sx={{ fontSize:20,backgroundColor: '#E7202C', color: 'white', width:200, height:'auto' }} >
                        ยกเลิก
                    </Button>
                    <Button variant='contained' onClick={handleAccept} sx={{ fontSize:20,backgroundColor: '#B5D86C', color: '#15322D', width:200, height:'auto' }}>
                        ยอมรับ
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AcceptRadio;
