import React from 'react';
import { Grid, Typography, Container, TextField, Box, Button, Autocomplete } from '@mui/material';
import moment from 'moment';

const OrderDetail = ({ orders }) => {
    return (
        <Container>
            <Grid container spacing={2} sx={{ border: '1px solid #ccc', borderRadius: 2 }}>

                <React.Fragment>
                    <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                        <Typography variant="body1" sx={{ fontWeight: '400', display: 'flex', justifyContent: 'center' }}>
                            เลขคำสั่งซื้อ
                        </Typography>
                    </Grid>
                    <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center', p: 1, borderBottom: '1px solid #ccc' }}>
                        <Typography px={{ fontSize: '20px' }}>
                            {orders?.orders_no}
                        </Typography>
                    </Grid>
                </React.Fragment>

                <React.Fragment>
                    <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                        <Typography variant="body1" sx={{ fontWeight: '400', display: 'flex', justifyContent: 'center' }}>
                            คู่ค้า
                        </Typography>
                    </Grid>
                    <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center', p: 1, borderBottom: '1px solid #ccc' }}>
                        <Typography px={{ fontSize: '20px' }}>
                            {orders?.vendor.firstname} {orders?.vendor.lastname}
                        </Typography>
                    </Grid>
                </React.Fragment>

                <React.Fragment>
                    <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                        <Typography variant="body1" sx={{ fontWeight: '400', display: 'flex', justifyContent: 'center' }}>
                            วันที่สั่ง
                        </Typography>
                    </Grid>
                    <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center', p: 1, borderBottom: '1px solid #ccc' }}>
                        <Typography px={{ fontSize: '20px' }}>
                            {moment(orders?.create_date).format('DD MMM YYYY')}
                        </Typography>
                    </Grid>
                </React.Fragment>

                <React.Fragment>
                    <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                        <Typography variant="body1" sx={{ fontWeight: '400', display: 'flex', justifyContent: 'center' }}>
                            วันที่ส่งมอบ
                        </Typography>
                    </Grid>
                    <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center', p: 1, borderBottom: '1px solid #ccc' }}>
                        <Typography px={{ fontSize: '20px' }}>
                            {moment(orders?.delivery_date).format('DD MMM YYYY')}
                        </Typography>
                    </Grid>
                </React.Fragment>

                <React.Fragment>
                    <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                        <Typography variant="body1" sx={{ fontWeight: '400', display: 'flex', justifyContent: 'center' }}>
                            สินค้า
                        </Typography>
                    </Grid>
                    <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center', p: 1, borderBottom: '1px solid #ccc' }}>
                        <Typography px={{ fontSize: '20px' }}>
                            {orders?.product_name}
                        </Typography>
                    </Grid>
                </React.Fragment>

                <React.Fragment>
                    <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                        <Typography variant="body1" sx={{ fontWeight: '400', display: 'flex', justifyContent: 'center' }}>
                           จำนวน 
                        </Typography>
                    </Grid>
                    <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center', p: 1, borderBottom: '1px solid #ccc' }}>
                        <Typography px={{ fontSize: '20px' }}>
                            {orders?.quantity}
                        </Typography>
                    </Grid>
                </React.Fragment>


                <React.Fragment>
                    <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                        <Typography variant="body1" sx={{ fontWeight: '400', display: 'flex', justifyContent: 'center' }}>
                            น้ำหนักสุทธิ
                        </Typography>
                    </Grid>
                    <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center', p: 1, borderBottom: '1px solid #ccc' }}>
                        <Typography px={{ fontSize: '20px' }}>
                            {orders?.total_weight}
                        </Typography>
                    </Grid>
                </React.Fragment>

                <React.Fragment>
                    <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                        <Typography variant="body1" sx={{ fontWeight: '400', display: 'flex', justifyContent: 'center' }}>
                            วันที่รับสินค้า
                        </Typography>
                    </Grid>
                    <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center', p: 1, borderBottom: '1px solid #ccc' }}>
                        <Typography variant="body1">
                        { orders?.accept_date ?  moment(orders?.accept_date).format('DD MMM YYYY') : '-'}
                        </Typography>
                    </Grid>
                </React.Fragment>


            </Grid>
        </Container>
    )
}
export default OrderDetail