import React, { useEffect, useState } from 'react';
import { Typography, Menu, MenuItem } from '@mui/material';
import { callService } from '../services/service';

const UsernameDisplay = ({ username }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const role_id = localStorage.getItem('role_id');
    const user_id = localStorage.getItem('user_id')
    const token = localStorage.getItem('accessToken')

    // useEffect(() => {
    //     callService('/user/roles', { 'user_id': user_id }, token)
    //         .then(res => {
    //             let result = res.data.data
    //             let list = []
    //             result.map(item => {
    //                 list.push({
    //                     id : item.role.id,
    //                     role : item.role.description
    //                 })
    //             })
    //         })
    // }, [])

    // Function to handle opening the menu
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Function to handle closing the menu
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Typography
                component="div"
                sx={{ color: 'red', marginRight: 2, fontSize: '18px', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={handleClick} // Open menu on click
            >
                {username}
            </Typography>
            {/* <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {role_id === '1' && <MenuItem onClick={handleClose}>Role 1</MenuItem>}
                {role_id === '2' && <MenuItem onClick={handleClose}>Role 2</MenuItem>}
                {role_id === '3' && <MenuItem onClick={handleClose}>Role 3</MenuItem>}
            </Menu> */}
        </>
    );
};

export default UsernameDisplay;
