import Table from "../table"
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { IconButton, Tooltip, Typography } from "@mui/material"

const ShippingTable = ({ rows, handleAccept }) => {
    const columns = [
        // { field: 'id', headerName: 'ID', width: 0, align: 'center' },
        { field: 'orders_no', headerName: 'เลขคำสั้งซื้อ', flex: 0.2, align: 'center' },
        { field: 'orderer', headerName: 'ผู้สั่ง', flex: 0.2, align: 'center' },
        { field: 'order_date', headerName: 'วันที่สั่ง', flex: 0.2, align: 'center' },
        { field: 'status', headerName: 'สถานะ', flex: 0.2, align: 'center' },
        { field: 'product', headerName: 'สินค้า', flex: 0.5, align: 'center' },
        { field: 'quantity', headerName: 'จำนวน', flex: 0.2, align: 'center' },
        { field: 'delivery_date', headerName: 'วันที่ส่งมอบ', flex: 0.2, align: 'center' },
        {
            field: "accept",
            headerName: "",
            flex: 0.05,
            align: 'center',
            renderCell: (params) => (
                <Tooltip title={<Typography>ตอบรับคำสั่งซื้อ</Typography>} >
                    <IconButton color="primary" onClick={() => handleAccept(params.row.id)}>
                        <ThumbUpOffAltIcon sx={{ color: '#15322D', fontSize: 35 }} />
                    </IconButton>
                </Tooltip>
            )
        },
       
    ]
    return (
        <div style={{ height: 400, width: "100%" }}>
            <Table
                columns={columns}
                rows={rows}
            />
        </div>
    )
}
export default ShippingTable