import axios from 'axios';
const config = require('../config.js');
const API_URL = config.API_URL;

export const getProfile = (id, token) => {
    return axios.post(`${API_URL}/user/get_profile`, { id: id }, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

export const getFarmerProfile = (token) => {
    return axios.post(`${API_URL}/farmer`, { }, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const updateProfile = (payload, token) => {
    return axios.post(`${API_URL}/user/update`, payload, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};