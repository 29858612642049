import { useEffect, useState } from "react";
import OrderDetail from "../../../../components/history/order-detail"
import Layout from "../../../../components/layout"
import Typography from '@mui/material/Typography'
import { useParams, useNavigate } from 'react-router-dom';
import OrderMaterial from "../../../../components/history/order-material";
import OrderMaterialAgent from "../../../../components/history/order-material-agent";
import OrderMaterialFarmer from "../../../../components/history/order-material-farmer";
import { getBatchOrders, getOrdersById } from "../../../../services/orders";
import Button from '@mui/material/Button'


const HistoryDetail = () => {
    const { order_id } = useParams()
    const [orders, setOrders] = useState()
    const [list_detail, setListDetail] = useState([])

    let token = localStorage.getItem('accessToken')
    let role_id = localStorage.getItem('role_id')
    let navigate = useNavigate()
    useEffect(() => {
        fetchOrderData()
        fetchBatchInOrders()
    }, [])

    const fetchOrderData = () => {
        getOrdersById(parseInt(order_id), token)
            .then(res => {
                let order = res.data.data
                

                setOrders(order)
                
            }).catch(error => {
                if (error.response.status === 403) {
                    navigate('/login')
                }
            })
    }

    const fetchBatchInOrders = () => {
        getBatchOrders({ order_id: order_id, role_id: role_id }, token)
            .then(res => {
                let detail = res.data.data
                let list = []
                console.log(detail)

                // batch.map(item => {
                //     list.push({
                //         'product': item.product,
                //         'batch_no': item.batch.batch_no,
                //         'from_order' : item.from_order,
                //         'order_id' : item.order_id,
                //         'quantity': item.quantity,
                //         'owner_name' : item.owner.firstname + ' ' + item.owner.lastname
                //     })
                // })
                list.push(detail)
                setListDetail(list)

            })
    }


    return (
        <Layout title='รายละเอียด'>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '2rem' }}>
                <OrderDetail orders={orders} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '2rem' }}>
                <Typography variant="body1" sx={{ textAlign:'center',backgroundColor: '#15322D', color: 'white',width : '70%',height : '50px', fontSize: '25px', borderRadius: 2 }}>
                    รายละเอียด
                </Typography>
            </div>
            {role_id === "1" && (
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '2rem' }}>
                    {list_detail.map((detail) => {
                        return <OrderMaterial key={detail.id} detail={detail} />; // Add return statement
                    })}
                </div>
            )}
            {role_id === "2" && (
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '2rem' }}>
                    {list_detail.map((detail) => {
                        return <OrderMaterialAgent key={detail.id} detail={detail} />; // Add return statement
                    })}
                </div>
            )}
            {role_id === "3" && (
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '2rem' }}>
                    {list_detail.map((detail) => {
                        return <OrderMaterialFarmer key={detail.id} detail={detail} />; // Add return statement
                    })}
                </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '2rem' }}>
                <Button
                    variant="contained"
                    sx={{ backgroundColor: '#15322D', color: 'white', paddingX: '10rem', fontSize: '20px' }}
                    onClick={() => navigate('/orders/history')}
                >
                    ย้อนกลับ
                </Button>
            </div>
        </Layout>
    )
}

export default HistoryDetail