import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'


const ProcessPopup = ({ open, onClose,onConfirm, listProduct,material_data, setMaterialQuantity, setProcessQuantity, setProduct}) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth sx={{ paddingTop: 2 }}  >
            <DialogTitle> แปรรูปวัตถุดิบ </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            value={material_data?.product}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type='number'
                            label="จำนวน"
                            inputProps={{ min: 0 }}
                            onChange={(event)=> setMaterialQuantity(event.target.value)}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Autocomplete
                            options={listProduct}
                            getOptionLabel={(option) => option.product}
                            fullWidth
                            renderInput={(params) => <TextField {...params} label="สินค้า" />}
                            onChange={(e,newValue) => setProduct(newValue)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id=""
                            type='number'
                            label="จำนวน"
                            inputProps={{ min: 0 }}
                        //  value={}
                            onChange={(event)=> setProcessQuantity(event.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => onClose()}
                    variant='contained'
                    sx={{
                        fontSize: 20,
                        backgroundColor: 'red',
                        color: 'white',
                        width: 100,
                        height: 'auto',
                        '&:hover': {
                            backgroundColor: '#821f0d', // Adjust the hover color as needed
                        },
                    }}
                    
                >
                    ยกเลิก
                </Button>
                <Button
                    onClick={() => onConfirm()}
                    variant='contained'
                    sx={{
                        fontSize: 20,
                        backgroundColor: '#B5D86C',
                        color: 'black',
                        width: 100,
                        height: 'auto',
                        '&:hover': {
                            backgroundColor: '#78a816', // Adjust the hover color as needed
                        },
                    }}
                >
                    ยืนยัน
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProcessPopup