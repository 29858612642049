import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Button } from '@mui/material';
import location_th from '../config/location_th.json'
const FarmerViewProfile = ({ farmerData, handleEditButton }) => {
    const [locationNames, setLocationNames] = useState(null)
    

    useEffect(() => {
        getNameById(farmerData, location_th)
        // setLocationNames(location)
    }, [farmerData])

    const getNameById = (id, data) => {
        
        if(id) {
        const province = data.PROVINCES.find(province => province.id === id.province);
        const amphure = province.amphure.find(amphure => amphure.id === id.amphoe);
        const tambon = amphure.tambon.find(tambon => tambon.id === id.tambon);
        setLocationNames(
            {
                province_th: province.name_th,
                province_en: province.name_en,
                amphure_th: amphure.name_th,
                amphure_en: amphure.name_en,
                tambon_th: tambon.name_th,
                tambon_en: tambon.name_en
            })
        }
    }
    

    return (
        <>

            <Container maxWidth="lg" sx={{ mt: 14, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                <Grid container spacing={2} sx={{ border: '1px solid #ccc', borderRadius: 2 }}>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                ขื่อ-นามสกุล
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                (full name)
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                            <Typography variant="body1">
                                {farmerData?.title_th} {farmerData?.name_th} {farmerData?.surname_th}
                            </Typography>

                            <Typography variant="body1">
                                {farmerData?.title_en} {farmerData?.name_en} {farmerData?.surname_en}
                            </Typography>
                        </Grid>
                    </React.Fragment>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                รหัสบัตรประชาขo
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                (Citizen ID)
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                            <Typography variant="body1">
                                {farmerData?.citizen_id}
                            </Typography>
                        </Grid>
                    </React.Fragment>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                บ้านเลขที่
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                (House No.)
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                            <Typography variant="body2">
                                {farmerData?.house_no}
                            </Typography>
                        </Grid>
                    </React.Fragment>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                ชื่อหมู่บ้าน
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                (Village Name)
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                            <Typography variant="body2">
                                {farmerData?.village_th}
                            </Typography>
                            <Typography variant="body2">
                                {farmerData?.village_en}
                            </Typography>
                        </Grid>
                    </React.Fragment>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                หมู่
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                (Moo)
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                            <Typography variant="body2">
                                {farmerData?.Moo}
                            </Typography>
                        </Grid>
                    </React.Fragment>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                ถนน
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                (Road)
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                            <Typography variant="body2">
                                {farmerData?.street_th}
                            </Typography>
                            <Typography variant="body2">
                                {farmerData?.street_en}
                            </Typography>
                        </Grid>
                    </React.Fragment>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                ซอย
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                (Soi)
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                            <Typography variant="body2">
                                {farmerData?.soi_th}
                            </Typography>
                            <Typography variant="body2">
                                {farmerData?.soi_en}
                            </Typography>
                        </Grid>
                    </React.Fragment>


                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                ตำบล
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                (Sub District)
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                            <Typography variant="body2">
                                {locationNames?.tambon_th}
                            </Typography>
                            <Typography variant="body2">
                                {locationNames?.tambon_en}
                            </Typography>
                        </Grid>
                    </React.Fragment>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                อำเภอ
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                (District)
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                            <Typography variant="body2">
                                {locationNames?.amphure_th}
                            </Typography>
                            <Typography variant="body2">
                                {locationNames?.amphure_en}
                            </Typography>
                        </Grid>
                    </React.Fragment>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                จังหวัด
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                (Province)
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                            <Typography variant="body2">
                                {locationNames?.province_th}
                            </Typography>
                            <Typography variant="body2">
                                {locationNames?.province_en}
                            </Typography>
                        </Grid>
                    </React.Fragment>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                รหัสไปรษณีย์
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                (Zip Code)
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                            <Typography variant="body2">
                                {farmerData?.zip_code}
                            </Typography>

                        </Grid>
                    </React.Fragment>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                โทรศัพท์
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                (Phone No.)
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                            <Typography variant="body2">
                                {farmerData?.phone_no}
                            </Typography>

                        </Grid>
                    </React.Fragment>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                โทรศัพท์มิอถือ
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                (Tel No.)
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                            <Typography variant="body2">
                                {farmerData?.tel_no}
                            </Typography>

                        </Grid>
                    </React.Fragment>


                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                อีเมล
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                (Email)
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                            <Typography variant="body2">
                                {farmerData?.email}
                            </Typography>

                        </Grid>
                    </React.Fragment>



                </Grid>
            </Container>
            <Container maxWidth="lg" sx={{ mt: 2, textAlign: 'center', p: 5 }}>
                <Button variant="contained"
                    onClick={handleEditButton}
                    sx={{
                        backgroundColor: '#15322D',
                        fontSize: 20,
                        textTransform: 'none',
                        fontWeight: 400,
                        px: 25,
                        '&:hover': {
                            backgroundColor: '#112824', // Change to your desired color
                        },
                    }}>
                    แก้ไขข้อมูล
                </Button>
            </Container>
        </>
    )
}
export default FarmerViewProfile