import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Button } from '@mui/material';
import { BrowserRouter as Router, Route, useParams } from 'react-router-dom';
import Layout from '../../../../components/layout';
import { getVendorById } from '../../../../services/vendor';
import { useNavigate } from 'react-router-dom'


const VendorDetail = () => {
    const { id } = useParams()
    const [ detail, setDetail ] = useState()
    const navigate = useNavigate()
    const token = localStorage.getItem('accessToken')
    
    useEffect(() => {
        fetchVendorData()
    },[])

    const fetchVendorData = () => {
        getVendorById(id,token)
        .then((res) => {
            let result = res.data.data
            console.log(result)
            setDetail({
                vendor_name : result.attributes.description,
                ownner_name : result.user.firstname + ' ' + result.user.lastname,
                address : result.attributes.detail_1,
                product : result.attributes.detail_2,
                tel_no : result.user.tel_no,
            })

        }).catch((error) =>{
            console.log(error)
            if(error.response.status === 403){
                navigate('/login')
            }
        })
    } 


    return (
        <Layout>
            <Container maxWidth="lg" sx={{ mt: 14, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                <Grid container spacing={2} sx={{ border: '1px solid #ccc', borderRadius: 2 }}>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                ชื่อโรงงานบริษัท
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                            <Typography variant="body2">
                               {detail?.vendor_name}
                            </Typography>
                        </Grid>
                    </React.Fragment>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                ชื่อเจ้าของ
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                            <Typography variant="body2">
                            {detail?.ownner_name}
                            </Typography>
                        </Grid>
                    </React.Fragment>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                สินค้า
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                            <Typography variant="body2">
                            {detail?.product}
                            </Typography>
                        </Grid>
                    </React.Fragment>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                              ที่อยู่โรงงาน/หัวจุด
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                            <Typography variant="body2">
                            {detail?.address}
                            </Typography>
                        </Grid>
                    </React.Fragment>

                    <React.Fragment>
                        <Grid item xs={4} sx={{ p: 2, backgroundColor: '#15322D', color: 'white', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>
                            <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                เบอร์ติดต่อ
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ p: 1, borderBottom: '1px solid #ccc' }}>
                            <Typography variant="body2">
                            {detail?.tel_no}
                            </Typography>
                        </Grid>
                    </React.Fragment>

                </Grid>
            </Container>
            <Container maxWidth="lg" sx={{ mt: 2, textAlign: 'center', p: 5 }}>
            <Button
                    onClick={()=> window.location.href = '/vendor_management'}
                    variant="contained"
                    sx={{
                        backgroundColor: '#C9D4C4',
                        color: '#15322D',
                        fontSize: 20,
                        '&:hover': {
                            backgroundColor: '#15322D',
                            color: 'white'
                        },
                        mr: 4,
                        px: 15,
                    }}
                >
                    กลับ
                </Button>
                {/* <Button
                    variant="contained"
                    // onClick={handleSaveButton}
                    sx={{
                        backgroundColor: '#B5D86C',
                        color: '#15322D',
                        fontSize: 20,
                        px: 15,
                        '&:hover': {
                            backgroundColor: '#15322D',
                            color: 'white'
                        },
                    }}
                >
                    เพิ่มคู่ค้า
                </Button> */}
            </Container>
        </Layout>
    )
}
export default VendorDetail