import { useEffect, useState } from "react"
import OrdersTable from "../../../components/table/orders-table"
import Layout from "../../../components/layout"
import { TextField, Box, Dialog, Button, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material"
import { getCookie } from "../../../utils/cookie"
import { getListOrdersByUser, orderStatusChange, saveBatch } from "../../../services/orders"
import { useNavigate } from 'react-router-dom'
import moment from "moment"
import Swal from 'sweetalert2'
const Dashboard = () => {
    const [list_orders, setOrders] = useState([])
    const [open, setOpen] = useState(false)
    const [remark, setRemark] = useState('')

    const user_id = localStorage.getItem('user_id')
    const token = localStorage.getItem('accessToken')
    const role_id = localStorage.getItem('role_id')
    const navigate = useNavigate()
    useEffect(() => {
        fetchListOrders()
    }, [])
    const fetchListOrders = () => {
        getListOrdersByUser(user_id, token)
            .then((res) => {
                if (res.status === 200) {
                    let data = res.data.data
                    console.log(data)
                    let list = []
                    data.map(item => {
                        list.push({
                            "id": item.id,
                            "orders_no": item.orders_no,
                            "orderer": item.user.firstname + ' ' + item.user.lastname,
                            "order_date": moment(item.create_date).format('DD MMM YYYY'),
                            "delivery_date": item.delivery_date ? moment(item.delivery_date).format('DD MMM YYYY') : '',
                            "product": item.product.name,
                            "brand" : item.brand?.brand_name,
                            "status": item.status_desc,
                            "total_weight": item.total_weight + ' ' + item.product.unit,
                            "quantity": item.quantity + ' ชิ้น',
                            "remark": item.remark ? item.remark : '-'
                        })
                    })
                    setOrders(list)
                }
            }).catch(error => {
                console.log(error.response)
                if (error.response.status === 403) {
                    navigate('/login')
                }
            })
    }
    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleOpenDialog = (remark) => {
        setRemark(remark)
        setOpen(true)
    }


    const handleAccept = (id) => {
        Swal.fire({
            title: "ยืนยันการรับสินค้าใช่หรือไม่",
            showCancelButton: true,
            confirmButtonText: "ยืนยัน",
            confirmButtonColor: '#B5D86C',
            cancelButtonText: "ยกเลิก",
            cancelButtonColor: '#E7202C'
        }).then((result) => {
            if (result.isConfirmed) {
                console.log(role_id)
                if (role_id === '1') {
                    orderStatusChange({
                        user_id: user_id,
                        status: 4,
                        order_id: id
                    }, token)
                        .then((res) => {
                            alert('ยอมรับสินค้าเสร็จสิ้น')
                            fetchListOrders()
                        }).catch(error => {
                            console.log(error)
                        })
                } else if (role_id === '2' || role_id === '3') {
                    saveBatch({
                        order_id: id,
                        status: 4
                    }, token)
                        .then(res => {
                            fetchListOrders()
                        }).catch(error => {
                            console.log(error)
                        })
                }
            } else {
                orderStatusChange({
                    user_id: user_id,
                    status: 5,
                    order_id: id
                }, token)
                    .then((res) => {
                        alert('ปฏิเสธสินค้าเสร็จสิ้น')
                        fetchListOrders()
                    }).catch(error => {
                        console.log(error)
                    })

            }
        })
    }
    return (
        <Layout title='รายการคำสั่งซื้อ Dashboard'>
            <Box my={2}>
                <OrdersTable rows={list_orders} handleAccept={handleAccept} handleOpenDialog={handleOpenDialog} />
            </Box>

            <Dialog open={open} onClose={handleCloseDialog} fullWidth>
                <DialogTitle sx={{ justifyContent: 'center', display: 'flex', fontSize: '24px' }}>หมายเหตุ</DialogTitle>
                <TextField
                    sx={{ padding: 4 }}
                    variant="outlined"
                    value={remark}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <div style={{ paddingBottom: 3, justifyContent: 'center', display: 'flex' }}>
                    <Button
                        onClick={handleCloseDialog}
                        variant="contained"
                        sx={{
                            px: 4,
                            backgroundColor: '#C9D4C4',
                            color: '#15322D',
                            fontSize: '20px',
                            '&:hover': {
                                backgroundColor: '#A2B29F',
                            },
                        }}
                    >
                        ย้อนกลับ
                    </Button>
                </div>
            </Dialog>

        </Layout>
    )
}

export default Dashboard