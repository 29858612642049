import Table from "../table"
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Tooltip, Typography } from "@mui/material"


const HistoryTable = ({rows}) => {
    const columns = [
        { field: 'orders_no', headerName: 'เลขคำสั้งซื้อ', flex: 0.2, align: 'center' },
        { field: 'orderer', headerName: 'ผู้สั่ง', flex: 0.2, align: 'center' },
        { field: 'order_date', headerName: 'วันที่สั่ง', flex: 0.2, align: 'center' },
        { field: 'status', headerName: 'สถานะ', flex: 0.2, align: 'center' },
        { field: 'product', headerName: 'สินค้า', flex: 0.5, align: 'center' },
        { field: 'quantity', headerName: 'จำนวน', flex: 0.2, align: 'center' },
        { field: 'delivery_date', headerName: 'วันที่ส่งมอบ', flex: 0.2, align: 'center' },
        {
            field: "detail",
            headerName: "",
            flex: 0.1,
            align: 'center',
            renderCell: (params) => (
                <Tooltip title={<Typography>รายละเอียด</Typography>} >
                    <IconButton color="primary" onClick={() => { window.location.href = 'history/' + params.row.id }}>
                        <SearchIcon sx={{ color: '#15322D', fontSize: 40 }} />
                    </IconButton>
                </Tooltip>
            ),
        }
    ]

    return (
        <div style={{ height: 400, width: "100%" }}>
            <Table 
                rows={rows}
                columns={columns}
            />
        </div>
    )
}

export default HistoryTable