import React, { useEffect } from 'react';
import { Grid, Typography, Container, TextField, Box, Button, Autocomplete, InputAdornment, OutlinedInput } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const AddOrders = ({ product, list_vendor_relations, list_material, handleInputChange, orders_form, handleOrdersClick, handleConfirmClick, handleBack, confirmMode }) => {

    useEffect(() => {
        if (orders_form.quantity && orders_form.weight_per_piece) {
            const totalWeight = parseFloat(orders_form.quantity) * parseFloat(orders_form.weight_per_piece);
            handleInputChange({ name: 'total_weight', value: totalWeight });
        } else {
            handleInputChange({ name: 'total_weight', value: '' });
        }
    }, [orders_form.quantity, orders_form.weight_per_piece]);


    return (
        <Container>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '10vh',
                }}
            >
                <form>
                    <Grid container spacing={3} alignItems="center" sx={{ textAlign: 'center', marginTop: 12 }}>
                        <Grid item xs={3}>
                            <Typography variant="body1" sx={{ backgroundColor: '#15322D', color: 'white', p: 2, borderRadius: '10px' }} >
                                สินค้า / spec (SKU)
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                fullWidth
                                value={product}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="body1" sx={{ backgroundColor: '#15322D', color: 'white', p: 2, borderRadius: '10px' }} >
                                วัตถุดิบ
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={list_material}
                                fullWidth
                                readOnly={confirmMode}
                                getOptionLabel={(option) => option.material}
                                onChange={(event, data) => {
                                    handleInputChange({ name: 'material', value: data })
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="body1" sx={{ backgroundColor: '#15322D', color: 'white', p: 2, borderRadius: '10px' }} >
                                เลือก vendor
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={list_vendor_relations}
                                fullWidth
                                readOnly={confirmMode}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, data) => {
                                    handleInputChange({ name: 'vendor', value: data })
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="body1" sx={{ backgroundColor: '#15322D', color: 'white', p: 2, borderRadius: '10px' }} >
                                น้ำหนักต่อชิ้น
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <OutlinedInput
                                variant="outlined"
                                fullWidth
                                inputProps={{ min: 0 }}
                                disabled={confirmMode}
                                value={orders_form.weight_per_piece}
                                endAdornment={<InputAdornment position="end">{orders_form.material?.unit}</InputAdornment>}
                                name="weightPerPiece"
                                onChange={(event) => handleInputChange({ name : 'weight_per_piece', value : event.target.value})}
                                type='number'
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="body1" sx={{ backgroundColor: '#15322D', color: 'white', p: 2, borderRadius: '10px' }} >
                                จำนวน 
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <OutlinedInput
                                variant="outlined"
                                fullWidth
                                inputProps={{ min: 0 }}
                                disabled={confirmMode}
                                value={orders_form.quantity}
                                endAdornment={<InputAdornment position="end">ชิ้น</InputAdornment>}
                                name="quantity"
                                onChange={(event) => handleInputChange({ name : 'quantity', value : event.target.value})}
                                type='number'
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="body1" sx={{ backgroundColor: '#15322D', color: 'white', p: 2, borderRadius: '10px' }} >
                                น้ำหนักสุทธิ
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <OutlinedInput
                                variant="outlined"
                                fullWidth
                                min="0"
                                disabled={true}
                                value={orders_form.total_weight}
                                endAdornment={<InputAdornment position="end">{orders_form.material?.unit}</InputAdornment>}
                                name="totalWeight"
                                onChange={(event) => handleInputChange({ name: 'total_weight', value: event.target.value })}
                                type='number'
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="body1" sx={{ backgroundColor: '#15322D', color: 'white', p: 2, borderRadius: '10px' }} >
                                กำหนดส่ง
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        sx={{ width: '100%' }}
                                        format="DD MMM YYYY"
                                        onChange={(value) => handleInputChange({ name: 'due_date', value: value })}
                                        value={orders_form.due_date}
                                    />
                                </DemoContainer>
                                </LocalizationProvider>
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="body1" sx={{ backgroundColor: '#15322D', color: 'white', p: 2, borderRadius: '10px' }} >
                                หมายเหตุ
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                disabled={confirmMode}
                                name="remark"
                                onChange={(e) => handleInputChange({ name: 'remark', value: e.target.value })}
                            />
                        </Grid>

                        {/* Add more form fields as needed */}
                        {!confirmMode ? (
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        px: 20,
                                        fontSize: 25,
                                        fontWeight: 500,
                                        backgroundColor: "#15322D",
                                        ":hover": {
                                            backgroundColor: '#15322D',
                                            color: 'white'
                                        }
                                    }}
                                    onClick={handleOrdersClick}

                                >
                                    สั่งซื้อ
                                </Button>
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            px: 10,
                                            fontSize: 25,
                                            fontWeight: 500,
                                            backgroundColor: "#C9D4C4",
                                            color: "#15322D",
                                            ":hover": {
                                                backgroundColor: '#15322D',
                                                color: 'white'
                                            }
                                        }}
                                        onClick={handleBack}
                                    >
                                        กลับไปแก้ไข
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            px: 14,
                                            fontSize: 25,
                                            backgroundColor: "#D5E9AC",
                                            fontWeight: 500,
                                            color: '#15322D',
                                            ":hover": {
                                                backgroundColor: '#15322D',
                                                color: 'white'
                                            },
                                        }}
                                        onClick={handleConfirmClick}
                                    >
                                        ยืนยัน
                                    </Button>
                                </Grid>
                            </>
                        )}



                    </Grid>
                </form>
            </Box>
        </Container>
    );
};

export default AddOrders;
