// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/layout';
import { ThemeProvider, createTheme } from '@mui/material';

const generateRoutes = (pagesContext) => {
  return pagesContext.keys().map((pagePath) => {
    const folderNamesMatch = pagePath.match(/\/([^/()]+)\/index\.js$/);

    if (folderNamesMatch && !/\([^()]*\)/.test(folderNamesMatch[1])) {
      const folderName = folderNamesMatch[1];
      const module = pagesContext(pagePath);

      const basePath = pagePath
        .replace(/\/index\.js$/, '')
        .replace(/^\.\//, '')
        .replace(/\[([^/]+)\]/g, ':$1'); // Replace [id] with :id

      return (
        <Route
          key={basePath}
          path={basePath}
          element={<module.default />}
        />
      );
    }

    return null; // Skip folders with parentheses
  }).filter(route => route !== null);
};

const App = () => {
  const theme = createTheme({
    typography : {
      fontFamily : [
        "'Mitr',sans-serif"
      ].join(',')
    }
  })

  const pagesContext = require.context('./pages', true, /\/([^/()]+)\/index\.js$/);
  const routes = generateRoutes(pagesContext);

  return (
     <ThemeProvider theme={theme}>
      <Router>
        <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
          {routes}
        </Routes>
      </Router>
      </ThemeProvider>

  );
};

export default App;
