import axios from "axios"
const config = require('../config.js')
const API_URL = config.API_URL


export const callService = (uri,payload,token) => {
    return axios.post(`${API_URL}` + uri, payload, {
        headers: {
           Authorization: `Bearer ${token}`
        }
    })
}

