import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import moment from 'moment';

const OrderMaterialFarmer = ({ detail }) => {
    const [expandedRows, setExpandedRows] = useState([]);

    const toggleRow = (rowId) => {
        const isRowExpanded = expandedRows.includes(rowId);
        setExpandedRows(
            isRowExpanded
                ? expandedRows.filter(id => id !== rowId)
                : [...expandedRows, rowId]
        );
    };


    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow sx={{ backgroundColor: '#214f47' }}>
                        <TableCell sx={{ color: 'white' }}>เลขชุด</TableCell>
                        <TableCell sx={{ color: 'white' }}>จำนวน</TableCell>
                        <TableCell sx={{ color: 'white' }}>วันที่เก็บเกี่ยว</TableCell>
                        <TableCell sx={{ color: 'white' }}>เวลาเก็บเกี่ยว</TableCell>
                        <TableCell sx={{ color: 'white' }}>เจ้าของ</TableCell>
                    </TableRow>
                </TableHead>
                {detail.map((item) => (
                    <TableBody>
                        <React.Fragment key={item.id}>
                            <TableRow onClick={() => toggleRow(item.id)}>
                                <TableCell>{item.batch_no}</TableCell>
                                <TableCell>{item.quantity}</TableCell>
                                <TableCell>{moment(item.harvest.harvest_date).format('DD MMM YYYY')}</TableCell>
                                <TableCell>{item.harvest.start_time} - {item.harvest.end_time}</TableCell>
                                <TableCell>{item.owner}</TableCell>
                            </TableRow>
                        </React.Fragment>
                    </TableBody>
                )
                )}
            </Table>
        </TableContainer>
    );
}

export default OrderMaterialFarmer;
