import React, { useEffect, useState } from 'react';
import Layout from '../../../components/layout';
import { TextField, Button, Grid, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, OutlinedInput, InputAdornment } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Autocomplete from '@mui/material/Autocomplete'
import { callService } from "../../../services/service";
import { useNavigate, useLocation } from "react-router-dom"
import moment from 'moment';
const WarehouseAdd = () => {
    const [product, setProduct] = useState({
        harvest_date: null,
        start_time: null,
        end_time: null,
        product: null,
        weight: '',
        remark: '',
        image: null
    });
    const [list_product, setListProduct] = useState([])
    const [confirm_open, setConfirmOpen] = useState(false)
    const [page_mode, setPageMode] = useState()
    const [selected_product, setSelectedProduct] = useState(null)
    const product_id = localStorage.getItem('product_id')
    const { state } = useLocation()

    const token = localStorage.getItem('accessToken')
    const navigate = useNavigate()


    useEffect(() => {
        loadProduct()
    }, [])

    useEffect(() => {
        if (state?.mode) {
            let mode = state.mode;
            if (mode === 2) {
                setPageMode(mode);
                setValueByName(state.product);
            }
        }

    }, [list_product]); // Add list_product as a dependency

    const findOptionByName = (name) => {
        return list_product.find(option => option.product === state.product);
    };


    const setValueByName = async (name) => {
        const option = await findOptionByName(name);

        setProduct({ ...product, product: option })

    };


    const loadProduct = () => {
        callService('/products', { product_id: product_id }, token)
            .then(res => {
                let response = res.data.data
                let list = []
                response.map((item) => {
                    list.push({
                        'id': item.attribute_id,
                        'product': item.prop,
                        'product_id': item.product_id,
                        'unit': item.unit
                    })
                })
                console.log(product_id)
                setListProduct(list)
            }).catch(error => {
                if (error.response.status === 403) {
                    navigate('/login')
                }
            })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProduct({
            ...product,
            [name]: value
        });
    };

    const validateProduct = () => {
        for (const key in product) {
            if (key !== 'remark' && key !== 'image') {
                if (product[key] === null || product[key] === '') {
                    return false; // Invalid
                }
            }
        }
        return true; // Valid
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateProduct()) {
            setConfirmOpen(true)
        } else {
            alert('โปรดกรอกข้อมูลให้ครบถ้วน')
        }
    };

    const handleConfirmClick = () => {

        let payload = {
            'product_id': product.product.product_id,
            'attribute_id': product.product.id,
            'quantity': product.weight,
            'harvest_date': product.harvest_date,
            'start_time': moment(new Date(product.start_time)).format('HH:mm:ss'),
            'end_time': moment(new Date(product.end_time)).format('HH:mm:ss'),
            'remark': product.remark
        }
        // console.log(payload)
        callService('/batch/createByFarmer', payload, token)
            .then(res => {
                if (res.status === 200) {
                    navigate('/warehouse')
                }
            }).catch(error => {
                if (error.response.status === 403) {
                    navigate('/login')
                }
            })
    }

    const handleConfirmClose = () => {
        setConfirmOpen(false)
    }



    return (
        <Layout title="คลังสินค้า">
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ px: '20rem', paddingTop: 5 }} >
                    <Grid item xs={4} >
                        <Typography variant="body1" align='center' sx={{ backgroundColor: '#15322D', color: 'white', padding: 2, borderRadius: 2 }}>
                            วันที่เก็บผลผลิต
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    sx={{ width: '100%' }}
                                    onChange={(newValue) => setProduct({ ...product, harvest_date: newValue })}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" align='center' sx={{ backgroundColor: '#15322D', color: 'white', padding: 2, borderRadius: 2 }}>
                            เวลาเก็บเกี่ยว
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['TimePicker']}>
                                <TimePicker
                                    label="เริ่มต้น"
                                    sx={{ width: '100%' }}
                                    onChange={(newValue) => setProduct({ ...product, start_time: newValue })}
                                    ampm={false}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['TimePicker']}>
                                <TimePicker
                                    label="สิ้นสุด"
                                    sx={{ width: '100%' }}
                                    onChange={(newValue) => setProduct({ ...product, end_time: newValue })}
                                    ampm={false}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" align='center' sx={{ backgroundColor: '#15322D', color: 'white', padding: 2, borderRadius: 2 }}>
                            สินค้า
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Autocomplete
                            options={list_product}
                            getOptionLabel={(option) => option.product}
                            fullWidth
                            value={product.product}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(e, newValue) => setProduct({ ...product, product: newValue })}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" align='center' sx={{ backgroundColor: '#15322D', color: 'white', padding: 2, borderRadius: 2 }}>
                            จำนวน (น้ำหนัก)
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <OutlinedInput
                            fullWidth
                            variant="outlined"
                            name="weight"
                            type='number'
                            endAdornment={<InputAdornment position="end">{selected_product?.unit}</InputAdornment>}
                            value={product.weight}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" align='center' sx={{ backgroundColor: '#15322D', color: 'white', padding: 2, borderRadius: 2 }}>
                            หมายเหตุ
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            name="remark"
                            value={product.remark}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" align='center' sx={{ backgroundColor: '#15322D', color: 'white', padding: 2, borderRadius: 2 }}>
                            อัพโหลดรูปภาพ
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            name="quantity"
                            type='file'
                            value={product.quantity}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button type="submit" variant="contained" sx={{ backgroundColor: '#15322D', fontSize: 22, px: 12 }}>
                            เพิ่มเข้าคลัง
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Dialog open={confirm_open} onClose={handleConfirmClose}>
                <DialogTitle>
                    ยืนยันการเพิ่มสินค้าเข้าคลัง ใช่ หรือ ไม่
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button
                        onClick={handleConfirmClick}
                        variant='contained'
                        sx={{ backgroundColor: '#B5D86C', color: 'black' }}
                    >
                        ใช่
                    </Button>
                    <Button
                        onClick={handleConfirmClose}
                        variant='contained'
                        sx={{ backgroundColor: '#E7202C', color: 'white' }}
                    >
                        ไม่
                    </Button>
                </DialogActions>
            </Dialog>
        </Layout>
    );
};

export default WarehouseAdd;
