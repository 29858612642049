
import { useState } from 'react';
import { Container, Paper, Typography, TextField, Button, Box, InputAdornment, IconButton, } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FiUser, FiLock } from 'react-icons/fi'
import axios from 'axios';
import { login } from '../../services/authen';
import { jwtDecode } from 'jwt-decode';

const LoginPage = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [isAuthorize, setAuthorize] = useState(true)
    const [loginForm, setLoginForm] = useState(
        {
            'username': '',
            'password': ''
        }
    )

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setLoginForm((prevLoginForm) => ({
            ...prevLoginForm,
            [name]: value,
        }));
    };

    const handleLogin = () => {
        setAuthorize(true)
        login(loginForm)
            .then((res) => {
                if (res.status === 200) {
                    let token = res.data.data
                    localStorage.setItem('accessToken', res.data.data)
                    let user = jwtDecode(res.data.data)
                    console.log(user)
                    localStorage.setItem('user_id', user.user_id)
                    localStorage.setItem('role_id', user.role.role_id)

                    localStorage.setItem('product_id', user.product_id)
                    localStorage.setItem('username',user.firstname + ' ' + user.lastname)
                    if (user.role.role_id !== 4) {
                        window.location.href = "/orders"
                    } else {
                        window.location.href = "/warehouse"
                    }
                }
            }).catch((err) => {
                let status = err.response.status
                if (status === 401) {
                    setAuthorize(false)
                }

            })
        // if (loginForm.username === 'DINadmin' && loginForm.password === 'password') {
        //     window.location.href = "/vendor_management/list"
        // } else {
        //     setAuthorize(false)
        // }
    }

    return (
        <div style={{ backgroundColor: '#C9D4C4', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Container component="main" maxWidth="xs" >
                <Paper elevation={3} style={{ padding: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 12, backgroundColor: '#F9FCF3' }}>
                    <Typography variant="h4" sx={{ fontWeight: '600' }} >เข้าสู่ระบบ</Typography>
                    <form style={{ width: '100%', marginTop: 30 }}>
                        <TextField
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            name="username"
                            value={loginForm.username}
                            onChange={handleInputChange}
                            placeholder='username'
                            InputProps={{
                                startAdornment: (
                                    <FiUser style={{ marginRight: 10, fontSize: 24 }} />
                                ),
                            }}
                        />
                        <TextField
                            variant="outlined"
                            margin="dense"
                            error={!isAuthorize}
                            fullWidth
                            value={loginForm.password}
                            onChange={handleInputChange}
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            placeholder='password'
                            InputProps={{
                                startAdornment: (
                                    <FiLock style={{ marginRight: 10, fontSize: 24 }} />
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        {
                            !isAuthorize &&
                            <Typography sx={{ color: '#E7202C', fontWeight: 300 }}>รหัสผ่านไม่ถูกต้องโปรดลองอีกครั้ง</Typography>
                        }

                        <div style={{ paddingTop: 30 }}>
                            <Button

                                fullWidth
                                variant="contained"
                                onClick={handleLogin}
                                style={{ textTransform: 'none', marginTop: 20, backgroundColor: '#15322D', fontWeight: 300, height: 50, fontSize: 20, borderRadius: 8 }}
                            >
                                Log in
                            </Button>
                        </div>
                    </form>
                </Paper>
            </Container>


        </div>
    )
}
export default LoginPage