import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import StoreIcon from '@mui/icons-material/Store';
import LoginIcon from '@mui/icons-material/Login';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import HistoryIcon from '@mui/icons-material/History';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import UsernameDisplay from './UsernameDisplay';


const drawerWidth = 350;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const ListSubItemButtonStyled = styled(ListItemButton, { shouldForwardProp: (prop) => prop !== 'selected' })(({ selected }) => ({
    backgroundColor: '#C9D4C4',
    '&:hover': {
        backgroundColor: '#15322D',
        color: 'white',
        '& .MuiSvgIcon-root': {
            color: 'white',
        },
    },
    ...(selected && {
        background: '#15322D',
        color: 'white',
        '& .MuiSvgIcon-root': {
            color: 'white',
        },
    }),
}));



const ListItemButtonStyled = styled(ListItemButton, { shouldForwardProp: (prop) => prop !== 'selected' })(({ selected }) => ({

    '&:hover': {
        backgroundColor: '#15322D',
        color: 'white',
        '& .MuiSvgIcon-root': {
            color: 'white',
        },
    },
    ...(selected && {
        background: '#15322D',
        color: 'white',
        '& .MuiSvgIcon-root': {
            color: 'white',
        },
    }),
}));

const ListItemIconStyled = styled(ListItemIcon, { shouldForwardProp: (prop) => prop !== 'selected' })(({ selected }) => ({

    '&:hover': {
        color: 'white'
    },
    ...(selected && {
        color: 'white'
    }),
}));



const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function Layout({ children, title }) {
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [openSubMenu, setOpenSubMenu] = useState(false)
    const location = useLocation();
    const role_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleSubMenuToggle = () => {
        setOpenSubMenu(!openSubMenu);
    };

    const handleLogoutClick = () => {
        localStorage.clear()
        window.location.href = '/login'
    }

    const fetchRole = () => {

    }

    const isItemSelected = (path) => location.pathname === path;

    return (
        <Box sx={{ position: 'absolute', backgroundColor: '#F9FCF3', display: 'flex', minHeight: '100vh', minWidth: '100%' }}>
            <AppBar position="fixed" sx={{ backgroundColor: '#15322D', padding: 3 }} open={open}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon fontSize='large' />
                    </IconButton>
                    <Typography variant="h4" noWrap component="div">
                        {title}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {username && <UsernameDisplay username={username} />} {/* Display the username */}
                        <Button
                            color='inherit'
                            sx={{
                                '&:hover': {
                                    color: '#D3D3D3',
                                },
                                fontSize: 20,
                            }}
                            onClick={handleLogoutClick}
                        >
                            Logout
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: '#F9FCF3',
                        boxShadow: 5,
                    },

                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader sx={{ padding: 3 }}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon fontSize='large' /> : <ChevronRightIcon fontSize='large' />}
                    </IconButton>
                </DrawerHeader>
                {role_id !== '1' && (
                    <List>
                        <ListItem disablePadding>
                            <ListItemButtonStyled
                                selected={isItemSelected('/warehouse')}
                                onClick={() => window.location.href = '/warehouse'}
                            >
                                <ListItemIcon>
                                    <WarehouseIcon />
                                </ListItemIcon>
                                <ListItemText primary='คลังสินค้า' />
                            </ListItemButtonStyled>
                        </ListItem>
                    </List>
                )}
                <Divider />
                <List >
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleSubMenuToggle}>
                            <ListItemIcon>
                                <ShoppingCartIcon />
                            </ListItemIcon>
                            <ListItemText primary='คำสั่งซื้อ' />
                            {openSubMenu ? <ArrowDropUpIcon fontSize='large' /> : < ArrowDropDownIcon fontSize='large' />}
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
                        <List>
                            <ListItem disablePadding>
                                <ListSubItemButtonStyled
                                    selected={isItemSelected('/orders')}
                                    onClick={() => window.location.href = '/orders'}
                                >
                                    <ListItemIcon>
                                        <AddShoppingCartIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='สั่งซื้อ' />
                                </ListSubItemButtonStyled>
                            </ListItem>
                            {role_id !== '1' && (
                                <ListItem disablePadding>
                                    <ListSubItemButtonStyled
                                        selected={isItemSelected('/orders/shipping')}
                                        onClick={() => window.location.href = '/orders/shipping'}
                                    >
                                        <ListItemIcon>
                                            <LocalShippingOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='รายการการจัดส่ง' />
                                    </ListSubItemButtonStyled>
                                </ListItem>
                            )}
                            <ListItem disablePadding>
                                <ListSubItemButtonStyled
                                    selected={isItemSelected('/orders/dashboard')}
                                    onClick={() => window.location.href = '/orders/dashboard'}
                                >
                                    <ListItemIcon>
                                        <ReceiptLongIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='รายการสั่งซื้อ' />
                                </ListSubItemButtonStyled>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListSubItemButtonStyled
                                    selected={isItemSelected('/orders/history')}
                                    onClick={() => window.location.href = '/orders/history'}
                                >
                                    <ListItemIcon>
                                        <HistoryIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='ประวัติการสั่งซื้อ' />
                                </ListSubItemButtonStyled>
                            </ListItem>
                        </List>
                    </Collapse>
                </List>
                <Divider />
                <List>
                    <ListItem disablePadding>
                        <ListItemButtonStyled
                            selected={isItemSelected('/vendor_management')}
                            onClick={() => window.location.href = '/vendor_management'}
                        >
                            <ListItemIcon>
                                <StoreIcon />
                            </ListItemIcon>
                            <ListItemText primary='คู่ค้า' />
                        </ListItemButtonStyled>
                    </ListItem>
                </List>
                <List>
                    <ListItem disablePadding>
                        <ListItemButtonStyled
                            selected={isItemSelected('/profile')}
                            onClick={() => window.location.href = '/profile'}
                        >
                            <ListItemIcon>
                                <SupervisorAccountIcon />
                            </ListItemIcon>
                            <ListItemText primary='ข้อมูลส่วนตัว' />
                        </ListItemButtonStyled>
                    </ListItem>
                </List>
                <List>
                    <ListItem disablePadding>
                        <ListItemButtonStyled
                            onClick={handleLogoutClick}
                        >
                            <ListItemIcon>
                                <LoginIcon />
                            </ListItemIcon>
                            <ListItemText primary='ออกจากระบบ' />
                        </ListItemButtonStyled>
                    </ListItem>
                </List>
            </Drawer>
            <Main sx={{ paddingTop: 15 }} open={open}>
                {children}
            </Main>
        </Box>
    );
}