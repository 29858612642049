import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import Container from '@mui/material/Container';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Button from '@mui/material/Button';
import NewOrders from '../../components/orders/new_orders';
import { getCookie } from '../../utils/cookie';
import { getVendorsByUserId } from '../../services/vendor';
import { getMaterials, getProducts, saveMaterialOrders, saveOrders } from '../../services/orders';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Swal from 'sweetalert2'
import AddOrders from '../../components/orders/add_orders';
import { People } from '@mui/icons-material';

const OrdersPage = () => {
    const token = localStorage.getItem('accessToken')
    const product_id = localStorage.getItem('product_id')
    const [list_vendor, setListVendor] = useState([])
    const [list_product, setListProduct] = useState([])
    const [list_material, setListMaterial] = useState([])
    const [page_mode, setPageMode] = useState(1)
    const [confirm_mode, setConfirmMode] = useState(false)
    const [orders_form, setOrdersForm] = useState(
        {
            'product': null,
            'material': null,
            'brand' : 0,
            'vendor': '',
            'quantity': '',
            'weight_per_piece' : '',
            'total_weight' : '',
            'due_date': null,
            'remark': ''

        }
    )
    const user_id = localStorage.getItem('user_id')
    const { state } = useLocation()
    const navigate = useNavigate()


    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([fetchVendorRelation(), fetchProductAttribute(), fetchMaterialData()]);

            if (state?.mode) {
                let mode = state.mode;
                if (mode) {
                    setPageMode(mode);
                }
            }
        };

        fetchData();
    }, [orders_form.brand]);

    useEffect(() => {
        fetchVendorRelation()
    }, []);


   


    const fetchVendorRelation = () => {
        let payload = {
            user_id : user_id,
            brand_id : orders_form.brand
        }
        getVendorsByUserId(payload, token)
            .then(res => {
                if (res.status === 200) {
                    let vendors = res.data.data
                    let list = []
                    vendors.map((item) => {
                        list.push({
                            'id': item.vendor_id,
                            'name':  item.attribute.name
                        })
                    })
                    setListVendor(list)
                }
            }).catch(error => {
                console.log(error)
                if (error.response.status === 403) {
                    navigate('/login')
                }
            })
    }

    const fetchProductAttribute = () => {
        console.log(product_id)
        let payload =  { 
            product_id : product_id
        }
        getProducts(token)
            .then((res) => {
                console.log(res.data.data)
                let products = res.data.data
                let list = []
                products.map((item) => {
                    list.push({
                        'id': item.attribute_id,
                        'product': item.prop,
                        'product_id': item.product_id,
                        'unit' : item.unit
                    })
                })
                setListProduct(list)
            }).catch(error => {
                console.log(error)
                if (error.response.status === 403) {
                    navigate('/login')
                }
            })
    }

    const fetchMaterialData = () => {

        let payload = {
            product_id : 1
        }
        getMaterials(payload, token)
            .then((res) => {
                let products = res.data.data
                let list = []
                products.map((item) => {
                    list.push({
                        'id': item.attribute_id,
                        'material': item.prop,
                        'product_id': item.product_id,
                        'unit' : item.unit
                    })
                })
                setListMaterial(list)
            }).catch(error => {
                console.log(error)
                if (error.response.status === 403) {
                    navigate('/login')
                }
            })
    }

    const handleNewOrderClick = () => {
        setPageMode(1)
    }

    const handleInputChange = (change) => {
        const { name, value } = change;

        if (name === 'due_date') {
            const momentDate = dayjs(value);

            if (momentDate.isValid()) {
                setOrdersForm((prevOrders) => ({
                    ...prevOrders,
                    [name]: momentDate.toDate(),
                }));
            } else {
                console.error('Invalid date format');
            }
        } else {
            setOrdersForm((prevOrders) => ({
                ...prevOrders,
                [name]: value,
            }));
        }
    };

    const isOrderFormValid = (orderForm) => {
        let requiredFields = []
        console.log(orderForm)
        if (page_mode === 1) {
            requiredFields = ['product', 'vendor', 'quantity', 'due_date'];
        }
        else if (page_mode === 2) {
            requiredFields = ['material', 'vendor', 'quantity', 'due_date'];
        }

        for (const field of requiredFields) {
            if (!orderForm[field]) {

                return false;
            }
        }

        return true;
    };

    const handleConfirmClick = () => {
        if (page_mode === 1) {
            let payload = {
                'user_id': user_id,
                'product_id': orders_form.product.product_id,
                'due_date': orders_form.due_date,
                'vendor_id': orders_form.vendor.id,
                'brand_id' : orders_form.brand,
                'total_weight' : orders_form.total_weight,
                'weight_per_piece' : orders_form.weight_per_piece,
                'quantity': parseFloat(orders_form.quantity),
                'attribute_id': orders_form.product.id,
                'remark': orders_form.remark
            }


            saveOrders(payload, token)
                .then((res) => {
                    if (res.status === 200) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Purchase Order Complete',
                            showConfirmButton: true
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = 'orders/dashboard'
                            }
                        })
                    }
                }).catch((error) => {
                    console.log(error)
                    if (error.response.status === 403) {
                        navigate('/login')
                    }
                })
        } else if (page_mode === 2) {
            let payload = {
                'user_id': user_id,
                'order_id': parseInt(state.order_id) ?? null,
                'material_id': orders_form.material.product_id,
                'due_date': orders_form.due_date,
                'vendor_id': orders_form.vendor.id,
                'quantity': parseFloat(orders_form.quantity),
                'total_weight' : parseFloat(orders_form.total_weight),
                'weight_per_piece' : parseFloat(orders_form.weight_per_piece),
                'attribute_id': orders_form.material.id,
                'remark': orders_form.remark
            }
            saveMaterialOrders(payload, token)
                .then((res) => {
                    if (res.status === 200) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Purchase Order Complete',
                            showConfirmButton: true
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate('/orders/dashboard')
                            }
                        })
                    }
                }).catch(error => {
                    if (error.response.status === 403) {
                        navigate('/login')
                    }
                })

        }
    }
    const handleBack = () => {
        setConfirmMode(false)
    }

    const handleOrdersClick = () => {
        
        if (isOrderFormValid(orders_form)) {
            setConfirmMode(true)
        } else {
            alert('โปรดกรอกข้อมูลให้ครบถ้วน (ยกเว้น หมายเหตุ)')
        }

    }

    return (
        <Layout title="คำสั่งซื้อ">
            {page_mode === 0 && (
                <Container
                    maxWidth="lg"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '80vh',
                    }}
                >
                    <AddBoxOutlinedIcon sx={{ fontSize: 100 }} />
                    <Button variant="contained" onClick={handleNewOrderClick} sx={{ marginTop: 2, px: 12, fontWeight: 300, fontSize: 30, backgroundColor: '#15322D', color: "white" }}>
                        สร้างรายการสั่งซื้อขึ้นใหม่
                    </Button>
                </Container>
            )}
            {page_mode === 1 && (
                <NewOrders
                    list_vendor_relations={list_vendor}
                    list_product={list_product}
                    handleInputChange={handleInputChange}
                    orders_form={orders_form}
                    handleOrdersClick={handleOrdersClick}
                    handleConfirmClick={handleConfirmClick}
                    handleBack={handleBack}
                    confirmMode={confirm_mode}
                />
            )}
            {page_mode === 2 && (
                <AddOrders
                    product={state?.product}
                    list_vendor_relations={list_vendor}
                    list_material={list_material}
                    handleInputChange={handleInputChange}
                    orders_form={orders_form}
                    handleOrdersClick={handleOrdersClick}
                    handleConfirmClick={handleConfirmClick}
                    handleBack={handleBack}
                    confirmMode={confirm_mode}
                />
            )}
        </Layout>
    );
};

export default OrdersPage;
